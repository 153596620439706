// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contents-tsx": () => import("./../../../src/pages/contents.tsx" /* webpackChunkName: "component---src-pages-contents-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-knowledges-tsx": () => import("./../../../src/pages/knowledges.tsx" /* webpackChunkName: "component---src-pages-knowledges-tsx" */),
  "component---src-pages-posts-tsx": () => import("./../../../src/pages/posts.tsx" /* webpackChunkName: "component---src-pages-posts-tsx" */),
  "component---src-templates-content-tsx": () => import("./../../../src/templates/Content.tsx" /* webpackChunkName: "component---src-templates-content-tsx" */),
  "component---src-templates-knowledge-tsx": () => import("./../../../src/templates/Knowledge.tsx" /* webpackChunkName: "component---src-templates-knowledge-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/Post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */)
}

